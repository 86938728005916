import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import {AppBar, Box, Container, IconButton, LinearProgress, Toolbar, Tooltip, Typography} from '@mui/material';
import {ExitToApp} from '@mui/icons-material';
import axios from 'axios';
import {useAuthCheck, useModuleAccessCheck} from 'hooks';

// App routes
import Users from 'views/Users';
import UserDetails from 'views/UserDetails';

const App = () => {
  const {currentUser} = useSelector(state => state.auth);
  const handleLogout = useCallback(async () => {
    document.cookie = `signOut=true.${Date.now()};domain=kbi.works;max-age=600`;
    try {
      await axios({
        method: 'post',
        url: `https://server.kbi.works/auth/signout`,
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
    }
    catch (error) {
      console.log('logout error', error);
    }
  }, []);

  useAuthCheck();
  useModuleAccessCheck();

  if (!currentUser) return <LinearProgress />;
  return (
    <Box sx={styles.root}>
      <AppBar position='fixed' sx={styles.appBar}>
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <Typography variant='h5' sx={styles.title}>Corporate Administration</Typography>
            <Tooltip title='Log Out' aria-label='Log Out'>
              <IconButton color='inherit' onClick={handleLogout} size='large'>
                <ExitToApp />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component='main' sx={styles.mainContent}>
        <Container maxWidth='xl'>
          <Routes>
            <Route strict={true} path='/users' element={<Users />} />
            <Route strict={true} path='/users/:UserId' element={<UserDetails />} />
            <Route element={<Navigate replace to='/users' />} path='*' />
          </Routes>
        </Container>
      </Box>
    </Box>
  );
};

const styles = {
  root: {
    display: 'flex',
    flexGrow: 1,
    minHeight: '100vh',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
  },
  appBar: {backgroundColor: 'primary.dark'},
  title: {flexGrow: 1},
  mainContent: {
    'flexGrow': 1,
    'backgroundColor': 'background.default',
    'marginTop': '64px',
    'minWidth': 0, // So the Typography noWrap works
    '@media print': {backgroundColor: '#FFFFFF'},
  },
};
export default App;
