import {initializeApp} from 'firebase/app';
import {getAuth, browserSessionPersistence, onAuthStateChanged, setPersistence, signInWithCustomToken, signInWithEmailAndPassword, signOut} from 'firebase/auth';
import {getFunctions, httpsCallable} from 'firebase/functions';

const isProductionEnv = process.env.NODE_ENV === 'production';
const FireProd = initializeApp({
  apiKey: 'AIzaSyDYqcc4pGWH0SYSQtYquHO7Ytujgto3Eg8',
  authDomain: 'batteries-fb.firebaseapp.com',
  databaseURL: 'https://batteries-fb.firebaseio.com',
  projectId: 'batteries-fb',
  storageBucket: 'batteries-fb.appspot.com',
  messagingSenderId: '550772834910',
  appId: '1:550772834910:web:7ee63409a7784a53ae6acf',
  measurementId: 'G-C7EWL86ZBG',
}, 'FireProd');
const FireDev = initializeApp({
  apiKey: 'AIzaSyAEiimSBP259_UbvLaZcyiPzdtXMeOlFXA',
  authDomain: 'batteries-fb-dev.firebaseapp.com',
  databaseURL: 'https://batteries-fb-dev.firebaseio.com',
  projectId: 'batteries-fb-dev',
  storageBucket: 'batteries-fb-dev.appspot.com',
  messagingSenderId: '967804234433',
  appId: '1:967804234433:web:359b46f0f14713cbd54240',
  measurementId: 'G-K1NLT3DV0J',
}, 'FireDev');

// Initialize Firebase Authentication with conditional override for development environment
const AuthInit = getAuth(isProductionEnv ? FireProd : FireDev);
const Auth = {
  currentUser: () => AuthInit.currentUser,
  onAuthStateChanged: cb => onAuthStateChanged(AuthInit, cb),
  setSessionPersistence: () => setPersistence(AuthInit, browserSessionPersistence),
  signInWithCustomToken: customToken => signInWithCustomToken(AuthInit, customToken),
  signInWithEmailAndPassword: (email, password) => signInWithEmailAndPassword(AuthInit, email, password),
  signOut: () => signOut(AuthInit),
};

// Initialize Firebase Functions with conditional override for development environment
const FunctionsInit = getFunctions(isProductionEnv ? FireProd : FireDev);
// Uncomment line below and add connectFunctionsEmulator to 'firebase/functions' import to test functions locally
// connectFunctionsEmulator(FunctionsInit, 'localhost', 5001);
const Functions = {
  httpsCallable: functionName => httpsCallable(FunctionsInit, functionName),
};

export {Auth, Functions};
