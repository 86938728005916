import React from 'react';
import {createRoot} from 'react-dom/client';
import * as serviceWorker from './serviceWorker.js';
import {BrowserRouter} from 'react-router-dom';
import App from './App.js';

// Import Redux Elements
import {Provider as ReduxProvider} from 'react-redux';
import store from 'state/store';

// Import Design Elements
import {CssBaseline} from '@mui/material';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import './index.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Apply to ag-grid by wrapping in <div className='ag-theme-alpine'>...</div>
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#6D6E71',
      light: '#9b9c9f',
      dark: '#424346',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ed1c24',
      light: '#ff5f4e',
      dark: '#b20000',
      contrastText: '#000000',
    },
    background: {
      paper: '#ffffff',
      default: '#eeeeee',
    },
  },
  shape: {borderRadius: 4},
  zIndex: {appBar: 1201},
});

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReduxProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
