/* eslint-disable new-cap */
import {Component} from 'react';
import PropTypes from 'prop-types';
import {IconButton, Tooltip} from '@mui/material';
import {MoreVert} from '@mui/icons-material';

/**
 * Component to render buttons within every row in a column
 */
class ActionIconRenderer extends Component {
  /**
   * Implement stanard React component bindings
   * @param {object} props Standard React component props passed from parent
   */
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }

  /**
  * Action to take when button is clicked
  * @param {func} onClick Standard React component props passed from parent
  */
  btnClickedHandler(onClick) {
    onClick({nodeData: this.props.data});
  }

  /**
  * BtnCellRenderer component UI
  * @return {component} The event triggered when a checkbox is clicked
  */
  render() {
    return this.props.actions.map((action, index) => {
      const Icon = action.Icon;
      return (
        <Tooltip title={typeof action.tooltip === 'string' ? action.tooltip : action.tooltip(this.props.data)} key={action.tooltip}>
          <IconButton size='small' style={styles.iconButton} onClick={() => this.btnClickedHandler(action.onClick)}>
            {typeof action.Icon === 'function' ? action.Icon(this.props.data) : <Icon />}
          </IconButton>
        </Tooltip>
      );
    });
  }
}

const styles = {
  iconButton: {marginRight: '4px'},
};
ActionIconRenderer.defaultProps = {
  actions: [{
    Icon: MoreVert,
    onClick: () => alert('Action clicked'),
    tooltip: '',
  }],
};
ActionIconRenderer.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    onClick: PropTypes.func.isRequired,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  })),
  data: PropTypes.object.isRequired,
};
export default ActionIconRenderer;
