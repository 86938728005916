import {useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import {useMediaQuery} from '@mui/material';
import {useDispatch} from 'react-redux';
import {uiActions} from 'state/ui/slice';

const useModal = () => {
  const transitionDuration = 350;
  const dispatch = useDispatch();

  // Logic to determin whether modal should display fullScreen on small devices
  const [fullScreen, setFullscreen] = useState(false);
  const theme = useTheme();
  const queryResult = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => setFullscreen(queryResult), [queryResult]);

  // Logic to control mmanagement of modal redux state
  const setModal = (newSettings) => dispatch(uiActions.setModal(newSettings));
  const clearModal = () => {
    dispatch(uiActions.setModal({open: false}));
    setTimeout(() => dispatch(uiActions.setModal({item: null, stage: 'initial', target: ''})), transitionDuration);
  };

  const defaultDialog = {
    fullScreen,
    fullWidth: true,
    maxWidth: 'sm',
    scroll: 'body',
    transitionDuration,
  };

  return {defaultDialog, setModal, clearModal};
};

export default useModal;
