import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Auth} from 'firebaseConfig';
import axios from 'axios';
import {authActions} from 'state/auth/slice';

const getCookie = cookieName => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');
  const foundCookie = cookies.find(c => c.includes(cookieName));
  if (foundCookie) return foundCookie.replace(cookieName + '=', '').trim();
  else return null;
};

const useAuthCheck = () => {
  const dispatch = useDispatch();
  const [csstCookie, setCsstCookie] = useState({value: null, checked: false});

  const signOut = useCallback(() => {
    const previousURL = window.location.href;
    document.cookie = `previousURL=${previousURL};domain=kbi.works;max-age=600`;
    Auth.signOut();
    window.location.replace('https://auth.kbi.works/');
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (process.env.NODE_ENV === 'development') return;
      const intervalCookie = getCookie('csst');
      setCsstCookie({value: intervalCookie, checked: true});
    }, csstCookie.checked ? 600000 : 0);
    return () => clearInterval(interval);
  }, [csstCookie.checked]);

  useEffect(() => {
    const csstLogin = async () => {
      try {
        const response = await axios({
          method: 'post',
          url: 'https://server.kbi.works/auth/check-status',
          withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${csstCookie.value}`,
          },
        });
        const customToken = response.data;
        await Auth.setSessionPersistence();
        await Auth.signInWithCustomToken(customToken);
      }
      catch (error) {
        console.log('csstLogin Error: ', error);
      }
    };
    const devLogin = async () => {
      try {
        await Auth.signInWithEmailAndPassword('devuser@kbirecycling.com', 'Luggage12345');
      }
      catch (error) {
        console.log('devLogin Error: ', error);
      }
    };
    const setCurrentUser = async () => {
      try {
        const idTokenResult = await Auth.currentUser().getIdTokenResult();
        dispatch(authActions.setCurrentUser({
          email: idTokenResult.claims.email,
          displayName: idTokenResult.claims.name,
          roleCatalyst: idTokenResult.claims.roleCatalyst,
          roleEHS: idTokenResult.claims.roleEHS,
          roleCRM: idTokenResult.claims.roleCRM,
          rolePortalAdmin: idTokenResult.claims.rolePortalAdmin,
          roleTracking: idTokenResult.claims.roleTracking,
          uid: idTokenResult.claims.user_id,
        }));
      }
      catch (error) {
        alert(error.message);
        console.log({error});
      }
    };

    const authListener = Auth.onAuthStateChanged(user => {
      if (process.env.NODE_ENV === 'development') {
        console.log('Dev Environment: Utilizing devuser@kbirecycling.com for testing.');
        if (!user) devLogin();
        else setCurrentUser();
      }
      else if (!csstCookie.checked) return;
      else if (user && csstCookie.value) setCurrentUser();
      else if (!user && csstCookie.value) csstLogin();
      else signOut();
    });
    return () => authListener();
  }, [csstCookie.value, csstCookie.checked, dispatch, signOut]);
};

export default useAuthCheck;
