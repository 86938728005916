import {configureStore} from '@reduxjs/toolkit';

// Import Admin Reducers
import AuthReducers from './auth/slice';
import UiReducer from './ui/slice';

const store = configureStore({
  reducer: {
    auth: AuthReducers,
    ui: UiReducer,
  },
});

export default store;
