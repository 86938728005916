import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  modal: {item: null, open: false, stage: 'initial', target: ''},
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = {...state.modal, ...action.payload};
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
