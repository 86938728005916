import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Functions} from 'firebaseConfig';
import {authActions} from 'state/auth/slice';

const useGetAllUsers = () => {
  const dispatch = useDispatch();
  const {users} = useSelector(state => state.auth);

  useEffect(() => {
    if (!users) {
      (async () => {
        try {
          const result = await Functions.httpsCallable('adminGetUsers')();
          dispatch(authActions.setUsers(result.data));
        }
        catch (error) {
          alert(error.message);
          console.error({error});
        }
      })();
    }
  }, [dispatch, users]);

  return {users};
};

export default useGetAllUsers;
