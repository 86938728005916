import {useEffect} from 'react';
import {useSelector} from 'react-redux';

const useModuleAccessCheck = () => {
  const currentUser = useSelector(state => state.auth.currentUser);

  useEffect(() => {
    if (currentUser && currentUser?.rolePortalAdmin !== true) {
      alert(`You do not have access to KBI's Admin module. Contact IT if you would like to request access.`);
      window.location.href = 'https://auth.kbi.works';
    }
  }, [currentUser]);
};

export default useModuleAccessCheck;
