const useGeneratePassword = () => {
  const generatePassword = length => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomValue = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      randomValue += charset.charAt(Math.floor(Math.random() * n));
    }
    return randomValue;
  };
  return generatePassword;
};

export default useGeneratePassword;
