import React, {Fragment, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Alert, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem} from '@mui/material';
import {FormikForm, FormButton, SelectField, SubmitButton, TextField} from '@kbi/story-forms';
import {boolean, object, string} from 'yup';
import {Functions} from 'firebaseConfig';
import {useGeneratePassword, useModal} from 'hooks';

const UserModal = () => {
  const modal = useSelector(state => state.ui.modal);
  const password = useGeneratePassword();
  const {clearModal, defaultDialog} = useModal();

  const handleSubmit = useCallback((values, actions) => {
    // Check if model item is new or existing user; Existing users will have a displayName, new items will be undefined.
    const existingUser = Boolean(modal?.item);

    // Structure userClaims that will be used whether new or existing user is being added or updated
    const userClaims = {
      customClaims: {
        rolePortalAdmin: values.rolePortalAdmin,
        roleTracking: values.roleTracking,
        roleCRM: values.roleCRM,
        roleCatalyst: values.roleCatalyst,
        roleEHS: values.roleEHS,
        roleXevStrategies: values.roleXevStrategies,
      },
      displayName: values.displayName,
      email: values.email,
    };

    if (!existingUser) {
      userClaims.password = values.password;
      (async () => {
        try {
          await Functions.httpsCallable('adminCreateUser')(userClaims);
          alert('New user successfully added. Page will refresh to load new user data.');
          window.location.reload(false);
        }
        catch (error) {
          actions.setSubmitting(false);
          actions.setStatus({text: error.message, severity: 'error'});
        }
      })();
    }
    else if (existingUser) {
      userClaims.active = values.active;
      userClaims.uid = modal.item.uid;
      (async () => {
        try {
          await Functions.httpsCallable('adminUpdateUser')(userClaims);
          alert('Existing user was successfully updated. Page will refresh to load new user data.');
          window.location.reload(false);
        }
        catch (error) {
          actions.setSubmitting(false);
          actions.setStatus({text: error.message, severity: 'error'});
        }
      })();
    }
  }, [modal.item]);
  const formProps = useMemo(() => ({
    initialStatus: {text: ''},
    initialValues: {
      active: modal.item?.active !== undefined ? modal.item.active : true,
      displayName: modal.item?.displayName || '',
      email: modal.item?.email || '',
      password: password(10),
      rolePortalAdmin: modal.item?.customClaims?.rolePortalAdmin !== undefined ? modal.item.customClaims.rolePortalAdmin : false,
      roleTracking: modal.item?.customClaims?.roleTracking || 'No Access',
      roleCRM: modal.item?.customClaims?.roleCRM || 'No Access',
      roleCatalyst: modal.item?.customClaims?.roleCatalyst || 'No Access',
      roleEHS: modal.item?.customClaims?.roleEHS || 'No Access',
      roleXevStrategies: modal.item?.customClaims?.roleXevStrategies || 'No Access',
    },
    validationSchema: object().shape({
      active: boolean().label('Active').required(),
      displayName: string().label('Display Name').required().min(4).trim(),
      email: string().label('Email').required().email(),
      password: (() => {
        if (modal?.item) return string().label('Password').notRequired();
        else return string().label('Password').required().min(6).trim();
      })(),
      rolePortalAdmin: string().label('Web Portal Admin').required(),
      roleTracking: string().label('Tracking Role').required(),
      roleCRM: string().label('CRM Role').required(),
      roleCatalyst: string().label('Catalyst Role').required(),
      roleEHS: string().label('EHS Role').required(),
      roleXevStrategies: string().label('xEV Strategies Role').required(),
    }),
    onSubmit: handleSubmit,
  }), [modal.item, handleSubmit, password]);

  return (
    <Dialog {...defaultDialog} open={modal.open && modal.target === 'UserModal'}>
      <FormikForm {...formProps}>
        {formik => (<Fragment>
          <DialogTitle>{modal.item ? 'Update' : 'Add New'} User</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SelectField name='active' label='Active' required disabled={Boolean(!modal.item)}>
                  <MenuItem key='True' value={true}>True</MenuItem>
                  <MenuItem key='False' value={false}>False</MenuItem>
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <TextField name='displayName' label='Display Name' required />
              </Grid>
              <Grid item xs={12}>
                <TextField name='email' label='Email' required />
              </Grid>
              {!modal?.item && (<Grid item xs={12}>
                <TextField name='password' label='Password' required />
              </Grid>)}
              <Grid item xs={12}>
                <SelectField name='rolePortalAdmin' label='Web Portal Admin' required>
                  <MenuItem key='True' value={true}>True</MenuItem>
                  <MenuItem key='False' value={false}>False</MenuItem>
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <SelectField name='roleTracking' label='Tracking Role'>
                  <MenuItem key='Admin' value='Admin'>Admin</MenuItem>
                  <MenuItem key='Manager' value='Manager'>Manager</MenuItem>
                  <MenuItem key='User' value='User'>User</MenuItem>
                  <MenuItem key='No Access' value='No Access'>No Access</MenuItem>
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <SelectField name='roleCRM' label='CRM Role'>
                  <MenuItem key='Admin' value='Admin'>Admin</MenuItem>
                  <MenuItem key='Manager' value='Manager'>Manager</MenuItem>
                  <MenuItem key='User' value='User'>User</MenuItem>
                  <MenuItem key='No Access' value='No Access'>No Access</MenuItem>
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <SelectField name='roleCatalyst' label='Catalyst Role'>
                  <MenuItem key='Admin' value='Admin'>Admin</MenuItem>
                  <MenuItem key='Manager' value='Manager'>Manager</MenuItem>
                  <MenuItem key='Buyer' value='Buyer'>Buyer</MenuItem>
                  <MenuItem key='Accounting' value='Accounting'>Accounting</MenuItem>
                  <MenuItem key='Laboratory' value='Laboratory'>Laboratory</MenuItem>
                  <MenuItem key='No Access' value='No Access'>No Access</MenuItem>
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <SelectField name='roleEHS' label='EHS Role'>
                  <MenuItem key='Admin' value='Admin'>Admin</MenuItem>
                  <MenuItem key='Manager' value='Manager'>Manager</MenuItem>
                  <MenuItem key='User' value='User'>User</MenuItem>
                  <MenuItem key='CAPA User' value='CAPA User'>CAPA User</MenuItem>
                  <MenuItem key='No Access' value='No Access'>No Access</MenuItem>
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <SelectField name='roleXevStrategies' label='xEV Strategies Role'>
                  <MenuItem key='Admin' value='Admin'>Admin</MenuItem>
                  <MenuItem key='Manager' value='Manager'>Manager</MenuItem>
                  <MenuItem key='User' value='User'>User</MenuItem>
                  <MenuItem key='No Access' value='No Access'>No Access</MenuItem>
                </SelectField>
              </Grid>
              {Boolean(formik.status.text) && (<Grid item xs={12}>
                <Alert severity={formik.status.severity || 'info'} style={{margin: '8px 0px'}}>
                  {formik.status.text}
                </Alert>
              </Grid>)}
            </Grid>
          </DialogContent>
          <DialogActions>
            <FormButton autoFocus onClick={clearModal} color='secondary' variant='text'>
              Cancel
            </FormButton>
            <SubmitButton color='primary' autoFocus variant='text'>
              Submit
            </SubmitButton>
          </DialogActions>
        </Fragment>)}
      </FormikForm>
    </Dialog>
  );
};

export default React.memo(UserModal);
