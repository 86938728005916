import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Paper, Button, Grid, List, ListItem, ListItemText, Typography, LinearProgress, Box} from '@mui/material';
import {Edit, AccountBox} from '@mui/icons-material';
import {useGetAllUsers, useModal} from 'hooks';
import {UserModal} from './Users/';

const UserDetails = () => {
  const {setModal} = useModal();
  const dispatch = useDispatch();
  const {users} = useGetAllUsers();
  const {currentUser} = useSelector(state => state.auth);
  const [user, setUser] = useState(null);
  const {UserId} = useParams();

  useEffect(() => {
    if (users && !user) {
      const user = users.find(user => user.uid === UserId);
      setUser(user);
    }
  }, [UserId, dispatch, user, users]);

  if (!currentUser || !users || !user) return <LinearProgress />;
  return (
    <Fragment>
      <UserModal />
      <Box sx={styles.marginTop} />
      <Button startIcon={<Edit />} size='small' variant='outlined' onClick={() => setModal({item: user, open: true, target: 'UserModal'})}>
        Edit
      </Button>
      <Grid spacing={5} container style={{marginTop: '8px'}}>
        <Grid item xs={12} md={3}>
          <Paper>
            <AccountBox style={{fontSize: '10em', fill: 'slategray', width: '100%'}} />
            <List style={{minWidth: '100%', maxWidth: '100%'}}>
              <ListItem style={{flexDirection: 'column'}}>
                <Typography variant='h6' style={{lineHeigt: '.9', textAlign: 'center'}}>
                  {user.displayName}
                </Typography>
              </ListItem>
              <ListItem style={{flexDirection: 'column'}}>
                <Typography variant='subtitle2' style={{lineHeigt: '.9', textAlign: 'center'}}>
                  {user.active ? 'Active' : 'Inactive'}
                </Typography>
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper>
            <Typography variant='h6' style={{lineHeigt: '.9', textAlign: 'center', paddingTop: 8}}>
              User Info
            </Typography>
            <Typography variant='subtitle1' style={{paddingLeft: 16, textDecoration: 'underline'}}>
              Claims
            </Typography>
            <List style={{marginLeft: 8}}>
              <ListItem>
                <ListItemText primary='CRM Role' secondary={user?.customClaims?.roleCRM} />
              </ListItem>
              <ListItem>
                <ListItemText primary='EH&S Role' secondary={user?.customClaims?.roleEHS || 'No Access'} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Catalyst Role' secondary={user?.customClaims?.roleCatalyst || 'No Access'} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Web Portal Admin' secondary={user.customClaims.rolePortalAdmin ? 'True' : 'False'} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Tracking Role' secondary={user?.customClaims?.roleTracking} />
              </ListItem>
              <ListItem>
                <ListItemText primary='xEV Strategies Role' secondary={user?.customClaims?.roleXevStrategies} />
              </ListItem>
            </List>
            <Typography variant='subtitle1' style={{paddingLeft: 16, textDecoration: 'underline'}}>
              Outlook Account
            </Typography>
            <List style={{marginLeft: 8}}>
              <ListItem>
                <ListItemText primary='Display Name' secondary={user.displayName} />
              </ListItem>
              <ListItem>
                <ListItemText primary='Email' secondary={user.email} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};
const styles = {
  marginTop: {marginTop: '24px'},
};
export default React.memo(UserDetails);
