import {Component} from 'react';
import {Checkbox, FormControl, FormControlLabel, FormGroup} from '@mui/material';
import PropTypes from 'prop-types';

/**
 * A custom filter component for ag-grid. Used to better accomodate a column with boolean values.
 * Example found here: https://www.ag-grid.com/documentation/react/component-filter/#react-filtering
 */
class BooleanFilter extends Component {
  /**
   * A custom filter component for ag-grid. Used to better accomodate a column with boolean values.
   * @param {object} props Standard React component props passed from parent
   */
  constructor(props) {
    super(props);

    // Both "True" and "False" checkboxes are checked by default
    this.state = {
      trueCheck: true,
      falseCheck: true,
    };

    this.valueGetter = this.props.valueGetter;

    this.onChange = this.onChange.bind(this);
  }

  /**
  * Return true if the filter is active. If active then 1) the grid will show the filter icon in the column
  * header and 2) the filter will be included in the filtering of the data.
  * @return {boolean}
  */
  isFilterActive() {
    // Filter is not active when both "True" and "False" checkboxes are checked.
    // Filter is active if either checkbox is not checked or both checkboxes are not checked.
    return !this.state.trueCheck || !this.state.falseCheck;
  }

  /**
  * The grid will ask each active filter, in turn, whether each row in the grid passes. If any
  * filter fails, then the row will be excluded from the final set. A params object is supplied
  * containing attributes of node (the rowNode the grid creates that wraps the data) and data (the data
  * object that you provided to the grid for that row).
  * @param {onject} params An object a "node" and "data" properties. "node" property represents the row node.
  * The "data" property contains the data part of the row node in question.
  * @return {boolean}
  */
  doesFilterPass(params) {
    // If node value === true, then node should return when "True" checkbox is checked
    if (this.valueGetter(params.node) && this.state.trueCheck) return true;
    // If node value === false, then node should return when "False" checkbox is checked
    else if (!this.valueGetter(params.node) && this.state.falseCheck) return true;
    // If above conditions are not satisfied, then filter out the node
    else return false;
  }

  /**
  * Gets the filter state. If filter is not active, then should return null/undefined. The grid calls
  * getModel() on all active filters when gridApi.getFilterModel() is called.
  * @return {object}
  */
  getModel() {
    return this.state;
  }

  /**
  * Restores the filter state. Called by the grid after gridApi.setFilterModel(model) is called.
  * The grid will pass undefined/null to clear the filter.
  * @param {object} model
  */
  setModel(model) {
    // Set filter state based on model; Default to true if model null/undefined
    this.setState({
      trueCheck: typeof model?.trueCheck === 'boolean' ? model.trueCheck : true,
      falseCheck: typeof model?.falseCheck === 'boolean' ? model.falseCheck : true,
    });
  }

  /**
  * Upddate state of "True" and "False" checkboxes when clicked; When state is finished updating,
  * trigger filterChangedCallback() to run isFilterActive() and doesFilterPass() methods.
  * @param {event} event The event triggered when a checkbox is clicked
  */
  onChange(event) {
    this.setState(
      {...this.state, [event.target.name]: event.target.checked},
      () => this.props.filterChangedCallback(),
    );
  }

  /**
  * BooleanFilter component UI
  * @return {component} The event triggered when a checkbox is clicked
  */
  render() {
    return (
      <div className='ag-filter-wrapper ag-focus-managed'>
        <div className='ag-filter-body-wrapper ag-simple-filter-body-wrapper'>
          <FormControl component='fieldset'>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={this.state.trueCheck} onChange={this.onChange} name='trueCheck' />}
                label='True'
              />
              <FormControlLabel
                control={<Checkbox checked={this.state.falseCheck} onChange={this.onChange} name='falseCheck' />}
                label='False'
              />
            </FormGroup>
          </FormControl>
        </div>
      </div>
    );
  }
}

BooleanFilter.propTypes = {
  filterChangedCallback: PropTypes.func.isRequired,
  valueGetter: PropTypes.func.isRequired,
};
export default BooleanFilter;
