import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, LinearProgress, Paper, Typography} from '@mui/material';
import {Search, Add} from '@mui/icons-material';
import {AgGridReact} from 'ag-grid-react';
import {ActionIconRenderer, BooleanFilter, compareBoolean, compareIgnoreCase} from 'ag-grid';
import {useGetAllUsers, useModal} from 'hooks';
import {UserModal} from './Users/';

const Users = () => {
  const {users} = useGetAllUsers();
  const {setModal} = useModal();
  const navigate = useNavigate();
  const gridOptions = useMemo(() => ({
    animateRows: true,
    columnDefs: [
      {type: 'actions'},
      {headerName: 'Active', field: 'active', type: 'boolean'},
      {headerName: 'Display Name', field: 'displayName'},
      {headerName: 'Email', field: 'email'},
      {headerName: 'User ID', field: 'uid'},
      {type: 'endFlex'}, // Add to stretch row to right edge of grid
    ],
    columnTypes: {
      'boolean': {
        valueFormatter: (param) => param.value ? 'True' : 'False',
        filter: 'BooleanFilter',
        comparator: compareBoolean,
      },
      'actions': {
        cellRenderer: 'ActionIconRenderer',
        cellRendererParams: {actions: [{
          Icon: Search,
          onClick: ({nodeData}) => navigate(`/users/${nodeData.uid}`),
          tooltip: 'Edit User Information',
        }]},
        field: 'actions',
        filter: false,
        headerName: '',
        resizable: false,
        sortable: false,
        suppressMovable: true,
        width: 75,
      },
      'endFlex': {
        field: 'endFlex',
        filter: false,
        headerName: '',
        resizable: false,
        sortable: false,
        suppressMovable: true,
        flex: 1,
      },
    },
    defaultColDef: {
      filter: true,
      resizable: true,
      sortable: true,
      comparator: compareIgnoreCase,
    },
    enableCellTextSelection: true,
    components: {ActionIconRenderer, BooleanFilter},
    getRowId: params => params.data.uid,
    onGridReady: params => {
      params.columnApi.applyColumnState({state: [{'colId': 'displayName', 'sort': 'asc'}]});
    },
    onModelUpdated: params => {
      params.columnApi.autoSizeColumns(['active', 'displayName', 'email', 'uid'], false);
      params.api.setDomLayout('autoHeight');
    },
    onRowDataUpdated: params => {
      // Refreshes ActionIconRenderer when new data passed to ag-grid
      params.api.refreshCells({columns: ['actions'], force: true});
    },
    pagination: true,
    paginationPageSize: 10,
  }), [navigate]);

  return (
    <Box sx={styles.tableMargin}>
      <UserModal />
      <Paper elevation={3}>
        {!users ? <LinearProgress color='secondary' style={styles.progress} /> : <div style={styles.progress} />}
        <Box style={styles.titleWrapper}>
          <Typography variant='h5' color='primary' style={styles.titleChild}>Portal Users</Typography>
          <Button color='primary' startIcon={<Add />} style={styles.titleChild} onClick={() => setModal({item: null, open: true, target: 'UserModal'})}>
            Add User
          </Button>
        </Box>
        <Box style={styles.gridWrapper} className='ag-theme-alpine'>
          <AgGridReact rowData={users || []} {...gridOptions} />
        </Box>
      </Paper>
    </Box>
  );
};

const styles = {
  gridWrapper: {height: '', width: '100%'},
  progress: {height: '2px'},
  tableMargin: {marginTop: '24px'},
  titleChild: {alignSelf: 'center'},
  titleWrapper: {
    alignItem: 'center',
    borderLeft: '1px solid #BABFC7',
    borderRight: '1px solid #BABFC7',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
};
export default React.memo(Users);
